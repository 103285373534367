<template>
  <iRow class="auth-form" align="center">
    <SplashWrapper
      :hide-buttons="true"
      title="Reset Password"
      :narrow-page="true"
    >
      <iColumn gap="extraLarge">
        <iRow>
          <iText variant="subtle">
            Enter your new password
          </iText>
        </iRow>

        <iRow>
          <iColumn>
            <iTextInput
              v-model="password"
              name="password"
              :focus="true"
              placeholder="***********"
              label="Password: "
              :required="true"
              width="fill"
              type="password"
              @change="validatePasswordComplexity"
            />
            <iTextInput
              v-model="confirmPassword"
              name="confirmPassword"
              placeholder="***********"
              label="Confirm Password: "
              :required="true"
              width="fill"
              :disabled="!validPassword"
              type="password"
              @change="validateConfirmPassword"
              @enter="submitResetPasswordForm"
            />

            <PasswordComplexity
              :password="password"
              :confirm-password="confirmPassword"
              @update:password="validatePasswordComplexity"
            />
          </iColumn>
          <iButton
            variant="primary"
            type="submit"
            width="fill"
            :disabled="!getPasswordComplexityCorrect"
            @click="submitResetPasswordForm"
          >
            Update Password
          </iButton>
          <iText v-if="hasError" variant="error">
            {{ resetPasswordErrorMessage }}
          </iText>
        </iRow>
      </iColumn>
    </SplashWrapper>
  </iRow>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user-store";
import PasswordComplexity from "@/components/PasswordComplexity";
import SplashWrapper from "@/components/splash/SplashWrapper.vue";

export default {
  name: "ResetPassword",
  components: {
    SplashWrapper,
    PasswordComplexity,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      passwordError: null,
      validPassword: null,
      validConfirmPassword: null,
    };
  },
  computed: {
    ...mapState(useUserStore, ["isLoading", "resetPasswordErrorMessage"]),
    getPasswordComplexityCorrect() {
      return this.validPassword && this.validConfirmPassword;
    },
    hasError() {
      return this.resetPasswordErrorMessage !== "";
    },
  },
  methods: {
    ...mapActions(useUserStore, ["resetPassword"]),
    submitResetPasswordForm() {
      this.resetPassword(this.userId, this.token, this.password, this.confirmPassword);
    },
    validatePasswordComplexity(value) {
      this.validPassword = value;
    },
    validateConfirmPassword(value) {
      return this.validConfirmPassword = this.password === value;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-form {
  margin: 0 auto;
  padding: 0 10px 10px 10px;

  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
  div {
    width: 600px;
    margin: 0 auto;
  }
}
</style>

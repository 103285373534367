<template>
  <PageWrapper
    :active-page="PAGE_STORIES_REQUIRES_APPROVAL"
    :active-section="SECTION_STORIES"
    title="Requires Approval"
    page-name="RequiresApproval"
  >
    <SmartFeed
      :pinia-store="useSyndicatedStoryFeedStore"
      default-entity-layout="tableRow"
      default-smart-feed-layout="table"
      sticky-headers
      vertical-align="middle"
    >
      <template #empty-state>
        <iText variant="subtle">
          No stories found.
        </iText>
      </template>
    </SmartFeed>
  </PageWrapper>
</template>

<script>
import { PAGE_STORIES_REQUIRES_APPROVAL, SECTION_STORIES } from "@/constants/nav-constants";
import PageWrapper from "@/components/PageWrapper.vue";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import {
  useSyndicatedStoryFeedStore,
} from "SmartFeed/stores/feed/my-syndicated-story-feed-store";

export default {
  name: "RequiresApproval",
  components: { SmartFeed, PageWrapper },
  data() {
    return {
      PAGE_STORIES_REQUIRES_APPROVAL,
      SECTION_STORIES,
    };
  },
  methods: { useSyndicatedStoryFeedStore },
};
</script>

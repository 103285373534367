<template>
  <PageWrapper :active-page="PAGE_SYNDICATED_STORY_EDITOR" :active-section="SECTION_STORIES" hide-sidebar>
    <SyndicatedStoryEditorComponent />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import SyndicatedStoryEditorComponent from "@/components/publication/SyndicatedStoryEditorComponent.vue";
import { PAGE_SYNDICATED_STORY_EDITOR, SECTION_STORIES } from "@/constants/nav-constants";

export default {
  name: "SyndicatedStoryEditor",
  components: {
    PageWrapper,
    SyndicatedStoryEditorComponent,
  },
  data() {
    return {
      PAGE_SYNDICATED_STORY_EDITOR,
      SECTION_STORIES,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <iRow>
    <iLink :href="`/stories`">
      <iSubHeading>My Stories</iSubHeading>
    </iLink>
  </iRow>
  <iRow class="side-nav" height="fill" overflow="scroll">
    <iMenu orientation="vertical" :active-item="activeStoryItem" :items="sideNavStories" />
  </iRow>
</template>

<script>
import { mapState } from "pinia";
import {
  PAGE_STORIES,
  PAGE_STORIES_SYNDICATION,
  PAGE_STORIES_REQUIRES_APPROVAL,
} from "@/constants/nav-constants";
import { useGeneralStore } from "%/stores/general-store";
import { useFeatureStore } from "%/stores/feature-store";
export default {
  name: "MyStoriesSideNav",
  props: {
    activeItem: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useGeneralStore, [
      "orgId",
    ]),
    isFeatureEnabled() {
      return useFeatureStore().isFeatureEnabled("syndicationMarketplace");
    },
    sideNavStories() {
      let menuItems = [
        {
          name: this.myMenuItemName(PAGE_STORIES, this.orgId),
          label: "My Stories",
          actionValue: "/stories",
        },
        ...this.isFeatureEnabled ? [{
          name: this.myMenuItemName(PAGE_STORIES_SYNDICATION, this.orgId),
          label: "Syndication",
          actionValue: "/stories/syndication",
          subItems: [
            {
              name: this.myMenuItemName(PAGE_STORIES_REQUIRES_APPROVAL, this.orgId),
              label: "Requires Approval",
              actionValue: "/stories/syndication/approval",
            },
          ],
        }] : [],
      ];
      return menuItems;
    },
    activeStoryItem() {
      return this.activeItem;
    },
  },
  methods: {
    myMenuItemName(name, nameSuffix) {
      return `${name}_${nameSuffix}`;
    },
  },
};
</script>

<template>
  <PageWrapper :active-page="PAGE_STORY_EDITOR" :active-section="SECTION_STORIES" hide-sidebar>
    <StoryEditorComponent />
  </PageWrapper>
</template>

<script>
import { PAGE_STORY_EDITOR, SECTION_STORIES } from "@/constants/nav-constants";
import PageWrapper from "@/components/PageWrapper.vue";
import StoryEditorComponent from "@/components/publication/StoryEditorComponent";

export default {
  name: "StoryEditor",
  components: {
    StoryEditorComponent,
    PageWrapper,
  },
  data() {
    return {
      PAGE_STORY_EDITOR,
      SECTION_STORIES,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>

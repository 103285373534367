<template>
  <iMultiTypeGraph
    v-if="loaded"
    :type="graphType"
    :x-axis-key="xAxisKey"
    :y-axis-key="yAxisKey"
    :dataset="dataset"
    :dataset-key="datasetAxisKey"
  />
</template>

<script>
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { Enum } from "@bloglovin/vue-component-library";
import { SmartFeedError } from "@bloglovin/vue-framework";

export default {
  name: "SmartFeedGraph",
  props: {
    smartFeedStore: {
      type: Object,
      required: true,
    },
    graphType: {
      type: Enum,
      options: ["line", "bar", "barStacked"],
      required: true,
    },
  },
  data() {
    return {
      dataset: [],
      loaded: false,
    };
  },
  computed: {
    xAxisKey() {
      if (!this.smartFeedStore.xAxisGraphKey) {
        throw new SmartFeedError("Smart feed of type graph must define an xAxisGraphKey property in its feed store", {
          smartFeedStoreId: this.smartFeedStore.$id,
        });
      }
      return this.smartFeedStore.xAxisGraphKey;
    },
    yAxisKey() {
      if (!this.smartFeedStore.yAxisGraphKey) {
        throw new SmartFeedError("Smart feed of type graph must define an yAxisGraphKey property in its feed store", {
          smartFeedStoreId: this.smartFeedStore.$id,
        });
      }
      return this.smartFeedStore.yAxisGraphKey;
    },
    datasetAxisKey() {
      return this.smartFeedStore.datasetAxisKey ?? "";
    },
  },
  async created() {
    const smartFeedEngine = new SmartFeedEngine();
    this.smartFeedStore.getSmartFeed().then(() => {
      this.smartFeedStore.feed.forEach(async feedItem => {
        const entity = await smartFeedEngine.getEntity(feedItem.type, feedItem.id);
        this.dataset.push(entity);
      });
    });
    this.loaded = true;
  },
};
</script>

/* eslint-disable camelcase */
import { ToastBuilder, validateEmail } from "@bloglovin/vue-component-library";
import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { EMAIL_FORM, PASSWORD_FORM, SIGN_UP_FORM } from "@/constants/login-flow-constants";
import { links } from "%/enums/link-enums";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useUserStore = defineStore("user-store", {
  state: () => ({
    userAuthEmail: "",
    userFlowSteps: [EMAIL_FORM],
    userFirstName: "",
    emailStatus: {
      requestedNewEmail: "",
      isEmailConfirmed: true,
      isEmailBeingChanged: false,
    },
    isLoading: false,
    loadingChangeFirstName: false,
    loadingChangeLastName: false,
    loginErrorMessage: "",
    signupErrorMessage: "",
    forgotPasswordErrorMessage: "",
    resetPasswordErrorMessage: "",
    verifyEmailErrorMessage: "",
    logoutErrorMessage: "",
    userDetailsErrorMessage: "",
    verifyUserErrorMessage: "",
    resetPasswordError: "",
    invitingOrgId: 0,
    invitationToken: "",
    changeEmailError: "",
    isModerator: false,
    redirect: "",
    loginWithInspirationRequestId: 0,
    loginWithInspirationRequestToken: "",
  }),
  getters: {
    appId() {
      return useGeneralStore().appId;
    },
    hasValidUserAuthEmail() {
      return validateEmail(this.userAuthEmail);
    },
    currentUserFlowStep() {
      return this.userFlowSteps.at(-1);
    },
    previousUserFlowStep() {
      return this.userFlowSteps.at(-2);
    },
    usersApiV1Url() {
      return useGeneralStore().getLink(links.usersApiV1);
    },
    emailData() {
      return this.userData.email_data ?? {};
    },
    userId() {
      return useGeneralStore().userId;
    },
    userDataNotEdited() {
      return (
        this.emailData.email_address === this.userData.emailStatus.requestedNewEmail
      );
    },
    userData() {
      return useGeneralStore().userData;
    },
  },
  actions: {
    uploadAvatar(avatarFile) {
      let formData = new FormData();
      formData.append("avatar", avatarFile);
      $axios.post("/user/avatar/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
        if (response.data.success) {
          useGeneralStore().setUserData(response.data.userData);
        }
      });
    },
    loginUser(password) {
      this.isLoading = true;
      $axios.post(
        "/user/login",
        {
          app_id: this.appId,
          email: this.userAuthEmail,
          password: password,
          login_with_inspiration_request_id: this.loginWithInspirationRequestId,
          login_with_inspiration_request_token: this.loginWithInspirationRequestToken,
          redirect: this.redirect,
        },
      ).then(response => {
        if (response.data.success) {
          window.location.href = this.redirect;
          return;
        }
        if (response.data.old_password) {
          this.loginErrorMessage = `You entered an old password, you changed it ${response.data.date_changed}`;
        } else {
          this.loginErrorMessage = "login failed for an unknown reason";
        }
      }).catch(error => {
        this.loginErrorMessage = this.parseError(error);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    parseError(error) {
      if (error.response.data.message) {
        if (error.response.data.message == "The password you entered is incorrect. Please try again.") {
          return "Incorrect password, please try again.";
        }
      }
      return "Login failed, please try again.";
    },

    signUpUser(firstName, lastName, orgName, email, password) {
      orgName = orgName || `${firstName} ${lastName}`;

      this.isLoading = true;
      $axios.post(
        "/user/create",
        {
          org_name: orgName,
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          app_id: this.appId,
          invitation_token: this.invitationToken,
          inviting_org_id: this.invitingOrgId,
          login_with_inspiration_request_id: this.loginWithInspirationRequestId,
          login_with_inspiration_request_token: this.loginWithInspirationRequestToken,
          redirect: this.redirect,
        },
      ).then(() => {
        window.location.href = this.redirect;
      }).catch(error => {
        if (error.message) {
          this.signupErrorMessage = error.message;
        } else {
          this.signupErrorMessage = "something unexpected went wrong during signup";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    acceptInvitation(userId, token, firstName, lastName, password, orgId) {
      this.isLoading = true;
      $axios.post(
        "/user/invitation/accept",
        {
          user_id: userId,
          first_name: firstName,
          last_name: lastName,
          password: password,
          app_id: this.appId,
          org_id: orgId,
          token: token,
        },
      ).then(response => {
        if (response.data.success) {
          window.location.href = "/";
          return;
        }
        this.signupErrorMessage = "something unexpected went wrong during signup";
      }).catch(error => {
        if (error.message) {
          this.signupErrorMessage = error.message;
        } else {
          this.signupErrorMessage = "something unexpected went wrong during signup";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    forgotPassword(email) {
      this.isLoading = true;
      $axios.post(
        "/user/forgot-password/initiate",
        {
          // eslint-disable-next-line camelcase
          app_id: this.appId,
          email: email,
        },
      ).then(response => {
        if (response.data.success) {
          new ToastBuilder()
              .setTitle("Confirm Email")
              .setText(response.data.reset_link)
              .setToastType(ToastBuilder.TOAST_TYPE_SUCCESS)
              .setTimeout(ToastBuilder.TIMEOUT_NONE)
              .setClosable(true)
              .build();
        }
        this.forgotPasswordErrorMessage = "something unexpectedly went wrong while trying to trigger forgot password";
      }).catch(error => {
        if (error.message) {
          this.forgotPasswordErrorMessage = error.message;
        } else {
          this.forgotPasswordErrorMessage = "something unexpectedly went wrong while trying to trigger forgot password";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    resetPassword(userId, token, password, confirmPassword) {
      this.isLoading = true;
      $axios.post(
        "/user/forgot-password/complete",
        {
          user_id: userId,
          token: token,
          new_password: password,
          confirm_new_password: confirmPassword,
        },
      ).then(() => {
        window.location.href = "/login";
      }).catch(error => {
        this.resetPasswordErrorMessage = error.response.data.message;
      }).finally(() => {
        this.isLoading = false;
      });
    },

    verifyEmail(userId, emailId, token) {
      this.isLoading = true;
      $axios.post(
        `${this.usersApiV1Url}/user/verify-email/app/${this.appId}`,
        {
          user_id: userId,
          email_id: emailId,
          token: token,
        },
      ).then(response => {
        if (response.data.success) {
          window.location.href = "/";
          return;
        }
        this.verifyEmailErrorMessage = "Something went wrong verifying email";
      }).catch(error => {
        if (error.message) {
          this.verifyEmailErrorMessage = error.message;
        } else {
          this.verifyEmailErrorMessage = "something unexpectedly went wrong resetting password";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    logoutUser() {
      this.isLoading = true;
      $axios.get("/user/logout").then(response => {
        if (response.data.success) {
          window.location.href = "/";
          return;
        }
        this.logoutErrorMessage = "something went wrong during logout";
      }).catch(error => {
        if (error.message) {
          this.logoutErrorMessage = error.message;
        } else {
          this.logoutErrorMessage = "something went wrong during logout";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    async verifyUserExists(email) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const verifyUserUri = `${this.usersApiV1Url}/user/app/${this.appId}/exists?email=${email}`;
      return $axios.get(verifyUserUri).then(response => {
        this.userAuthEmail = email;

        if (response.data.exists === false) {
          this.userFlowSteps.push(SIGN_UP_FORM);
          return { email, exists: false };
        }

        this.userFirstName = response.data.first_name;
        this.userFlowSteps.push(PASSWORD_FORM);

        return { email, exists: true };
      }).catch(error => {
        if (error.message) {
          this.verifyUserErrorMessage = error.message;
        } else {
          this.verifyUserErrorMessage = "something went wrong checking to see if user has account";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    fetchEmailStatus() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      $axios.get(`${this.usersApiV1Url}/user/${this.userId}/email/status`)
          .then(response => {
            if (response.data.success) {
              let emailStatus = response.data.email_status;
              if (emailStatus.isEmailConfirmed) {
                emailStatus.requestedNewEmail = this.emailData.email_address;
              }

              this.emailStatus = emailStatus;
            }
            if (!response.data.success) {
              this.userDetailsErrorMessage = response.data.message;
            }
            this.isLoading = false;
          })
          .catch(error => {
            if (error.message) {
              this.userDetailsErrorMessage = error.message;
            } else {
              this.userDetailsErrorMessage = "Check email status failed.";
            }
          });
    },

    cancelEmailChange() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      $axios.post(`${this.usersApiV1Url}/user/${this.userId}/email/cancel-change`)
          .then(response => {
            if (!response.data.success) {
              this.userDetailsErrorMessage = response.data.message;
              this.isLoading = false;
              return;
            }
            this.emailStatus.isEmailConfirmed = true;
            this.isLoading = false;
          })
          .catch(error => {
            if (error.message) {
              this.userDetailsErrorMessage = error.message;
            } else {
              this.userDetailsErrorMessage = "Cancel email change process failed.";
            }
          });
    },

    submitNewEmail(email) {
      if (this.isLoading) {
        return;
      }
      if (this.emailData.email_address === email) {
        this.changeEmailError = "New email cannot be current email";
        return;
      }
      this.isLoading = true;
      $axios.post(`${this.usersApiV1Url}/user/${this.userData.userId}/email/initiate-change`, {
        new_email: email,
        app_id: this.appId,
      })
          .then(response => {
            if (response.data.success) {
              this.emailStatus.isEmailConfirmed = false;
              this.isLoading = false;
            }
            this.userDetailsErrorMessage = response.data.message;
            this.isLoading = false;
          })
          .catch(() => {
            this.changeEmailError = "Unable to change email. Please try again.";
            this.isLoading = false;
          });
    },

    submitNewFirstName(firstName) {
      if (this.loadingChangeFirstName) {
        return;
      }
      this.loadingChangeFirstName = true;
      $axios.post(`${this.usersApiV1Url}/user/${this.userData.userId}/first_name/change`, {
        first_name: firstName,
      }).then(response => {
        this.loadingChangeFirstName = false;
        const userData = useGeneralStore().userData;
        useGeneralStore().setUserData({
          ...userData,
          first_name: response.data.user_data.first_name,
        });
      }).catch(error => {
        if (error.message) {
          this.userDetailsErrorMessage = error.message;
        } else {
          this.userDetailsErrorMessage = "Submit new first name failed";
        }
        this.loadingChangeFirstName = false;
      });
    },

    submitNewLastName(lastName) {
      if (this.loadingChangeLastName) {
        return;
      }
      this.loadingChangeLastName = true;
      $axios.post(`${this.usersApiV1Url}/user/${this.userData.userId}/last_name/change`, {
        last_name: lastName,
      }).then(response => {
        useGeneralStore().setUserData({
          ...this.userData,
          last_name: response.data.user_data.last_name,
        });
        this.loadingChangeLastName = false;
      }).catch(error => {
        if (error.message) {
          this.userDetailsErrorMessage = error.message;
        } else {
          this.userDetailsErrorMessage = "Submit new last name failed";
        }
        this.loadingChangeLastName = false;
      });
    },
    submitNewPassword(currentPassword, newPassword) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      $axios.post(`${this.usersApiV1Url}/user/${this.userId}/password/change`, {
        current_password: currentPassword,
        new_password: newPassword,
      })
          .then(response => {
            if (!response.data.success) {
              this.userDetailsErrorMessage = response.data.message;
            }
            this.isLoading = false;
          })
          .catch(error => {
            if (error.message) {
              this.userDetailsErrorMessage = error.response.data.message;
              return;
            }
            this.userDetailsErrorMessage = "Submit new password failed";
          });
    },
    completeOffPlatformInviteRegistration(orgId, userId, firstName, lastName, orgName, email, password, token) {
      this.isLoading = true;
      $axios.post(
        `${this.usersApiV1Url}/org/${orgId}/user/${userId}/complete-invite-registration`,
        {
          first_name: firstName,
          last_name: lastName,
          org_name: orgName,
          email: email,
          password: password,
          app_id: this.appId,
          token: token,
        },
      ).then(response => {
        if (response.data.success) {
          window.location.href = "/";
          return;
        }
        this.signupErrorMessage = "Something unexpected went wrong during registration";
      }).catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          this.signupErrorMessage = error.response.data.message;
        } else if (error.message) {
          this.signupErrorMessage = error.message;
        } else {
          this.signupErrorMessage = "Something unexpected went wrong during registration";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
    acceptUser(userId, publicationId) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      $axios.post(`/user/${userId}/publication/${publicationId}/accept`, {}).then(response => {
        new SmartFeedEngine().addEntities(response.data.entities);
      }).catch(error => {
        if (error.message) {
          this.userDetailsErrorMessage = error.message;
        } else {
          this.userDetailsErrorMessage = "Failed to accept user.";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    rejectUser(userId, publicationId) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      $axios.post(`/user/${userId}/publication/${publicationId}/reject`, {}).then(response => {
        new SmartFeedEngine().addEntities(response.data.entities);
      }).catch(error => {
        if (error.message) {
          this.userDetailsErrorMessage = error.message;
        } else {
          this.userDetailsErrorMessage = "Failed to reject user.";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
});

import {
  camelToKebabCase,
  camelToTitleCase,
  snakeToTitleCase,
  SmartFeedError,
} from "@bloglovin/vue-framework";

const BaseSmartFeedEngine = class {
  static USE_PINIA_STORE = "usePiniaStore";
  static LAYOUT_COMPONENTS = "layoutComponents";

  static ENTITY_LAYOUT_CARD_LARGE = "cardLarge";
  static ENTITY_LAYOUT_CARD_SMALL = "cardSmall";
  static ENTITY_LAYOUT_TABLE_ROW = "tableRow";
  static ENTITY_LAYOUT_GRAPH_LINE = "line";
  static ENTITY_LAYOUT_GRAPH_BAR = "bar";
  static ENTITY_LAYOUT_GRAPH_BAR_STACKED = "barStacked";

  static SMART_FEED_LAYOUT_CAROUSEL = "carousel";
  static SMART_FEED_LAYOUT_GRID = "grid";
  static SMART_FEED_LAYOUT_LIST = "list";
  static SMART_FEED_LAYOUT_TABLE = "table";
  static SMART_FEED_LAYOUT_GRAPH = "graph";

  static VALID_ENTITY_LAYOUTS = [
    this.ENTITY_LAYOUT_CARD_LARGE,
    this.ENTITY_LAYOUT_CARD_SMALL,
    this.ENTITY_LAYOUT_TABLE_ROW,
    this.ENTITY_LAYOUT_GRAPH_LINE,
    this.ENTITY_LAYOUT_GRAPH_BAR,
    this.ENTITY_LAYOUT_GRAPH_BAR_STACKED,
  ];

  static VALID_SMART_FEED_LAYOUTS = [
    this.SMART_FEED_LAYOUT_CAROUSEL,
    this.SMART_FEED_LAYOUT_GRID,
    this.SMART_FEED_LAYOUT_LIST,
    this.SMART_FEED_LAYOUT_TABLE,
    this.SMART_FEED_LAYOUT_GRAPH,
  ];

  async getStoreFunctionForEntity(entityType) {
    const titleCaseEntityType = camelToTitleCase(entityType, false);
    const kebabCaseEntityType = camelToKebabCase(entityType);
    const storeName = `use${titleCaseEntityType}EntityStore`;

    const resolvedDynamicImport = await import(
      `SmartFeed/stores/entity/${kebabCaseEntityType}-entity-store`
    );
    return resolvedDynamicImport[storeName];
  }

  async getLayoutComponentForEntity(entityType, layoutType) {
    if (layoutType === BaseSmartFeedEngine.SMART_FEED_LAYOUT_GRAPH) {
      throw new SmartFeedError("Cannot get layout component for layout type", { layoutType, entityType });
    }

    const titleCaseEntityType = snakeToTitleCase(entityType, false);
    const kebabCaseEntityType = camelToKebabCase(entityType);
    const titleCaseLayoutType = snakeToTitleCase(layoutType, false);

    const resolvedDynamicImport = await import(
      `SmartFeed/components/${kebabCaseEntityType}/layouts/${titleCaseEntityType}${titleCaseLayoutType}`
    );
    return resolvedDynamicImport.default;
  }

  async getEntity(entityType, entityId) {
    const usePiniaStore = await this.getStoreFunctionForEntity(entityType);
    const store = usePiniaStore();
    return store.getEntity(entityId);
  }

  async getEntityStore(entityType) {
    const usePiniaStore = await this.getStoreFunctionForEntity(entityType);
    return usePiniaStore();
  }

  async addEntities(entities) {
    for (const [entityType, entityItems] of Object.entries(entities)) {
      const usePiniaStore = await this.getStoreFunctionForEntity(
        entityType,
      );
      const store = usePiniaStore();
      store.addEntities(entityItems);
    }
  }
};

export default BaseSmartFeedEngine;

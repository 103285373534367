import { validateEmail } from "@bloglovin/vue-component-library";
import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { useFanPublicationStore } from "%/stores/fan-publication-store";
import { EMAIL_FORM, PASSWORD_FORM, PRIVATE_NOTICE, SIGN_UP_FORM } from "@/constants/login-flow-constants";
import { links } from "%/enums/link-enums";
import { useSessionStorage } from "@vueuse/core";
import { useConfigurationStore } from "%/stores/configuration-store";

export const useUserStore = defineStore("user-store", {
  state: () => ({
    userAuthEmail: useSessionStorage("userAuthEmail", ""),
    userFlowSteps: useSessionStorage("userFlowSteps", [EMAIL_FORM]),
    userFirstName: "",
    emailStatus: {
      requestedNewEmail: "",
      isEmailConfirmed: true,
      isEmailBeingChanged: false,
    },
    isLoading: false,
    loadingChangeFirstName: false,
    loadingChangeLastName: false,
    loadingChangeAvatar: false,
    loginErrorMessage: "",
    signupErrorMessage: "",
    forgotPasswordErrorMessage: "",
    resetPasswordErrorMessage: "",
    verifyEmailErrorMessage: "",
    logoutErrorMessage: "",
    userDetailsErrorMessage: "",
    verifyUserErrorMessage: "",
    resetPasswordError: "",
    invitingOrgId: 0,
    invitationToken: "",
    changeEmailError: "",
    isModerator: false,
    redirect: "",
    isEditingUserData: false,
  }),
  getters: {
    appId() {
      return useGeneralStore().appId;
    },
    hasValidUserAuthEmail() {
      return validateEmail(this.userAuthEmail);
    },
    currentUserFlowStep() {
      return this.userFlowSteps.at(-1);
    },
    previousUserFlowStep() {
      return this.userFlowSteps.at(-2);
    },
    usersApiV1Url() {
      return useGeneralStore().getLink(links.usersApiV1);
    },
    creatorApiV1Url() {
      return useGeneralStore().getLink(links.api);
    },
    fanApiV1Url() {
      return useGeneralStore().getLink(links.fanApiV1);
    },
    creatorApiV1() {
      return useGeneralStore().getLink(links.api);
    },
    userId() {
      return useGeneralStore().userId;
    },
    userData() {
      return useGeneralStore().userData;
    },
    emailData() {
      return this.userData.email_data ?? {};
    },
    userAvatar() {
      return useGeneralStore().userAvatar;
    },
    userDataNotEdited() {
      return (
        this.emailData.email_address === this.userData.emailStatus.requestedNewEmail
      );
    },
    firstName() {
      return this.userData.first_name ?? "";
    },
    lastName() {
      return this.userData.last_name ?? "";
    },
    currentEmail() {
      return this.emailData.email_address ?? "";
    },
    isEmailConfirmed() {
      return this.emailStatus.isEmailConfirmed;
    },
    requestedEmailAddress() {
      return this.emailStatus.requestedNewEmail;
    },
  },
  actions: {
    uploadAvatar(avatarFile) {
      if (this.loadingChangeAvatar) {
        return;
      }
      this.loadingChangeAvatar = true;
      let formData = new FormData();
      formData.append("avatar", avatarFile);
      $axios.post(`${this.creatorApiV1}/user/avatar/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
        if (response.data.success) {
          useGeneralStore().setUserData(response.data.userData);
          this.loadingChangeAvatar = false;
        }
      });
    },
    loginUser(password) {
      this.isLoading = true;
      $axios.post(
        `${this.fanApiV1Url}/user/login`,
        {
          app_id: this.appId,
          email: this.userAuthEmail,
          password: password,
          publication_id: useFanPublicationStore().publicationId,
        },
      ).then(response => {
        if (response.data.success) {
          if (this.redirect) {
            if (this.redirect.startsWith("http")) {
              window.location.href = `${this.redirect}`;
            } else {
              window.location.href = `https://${window.location.hostname}${this.redirect}`;
            }
          } else {
            window.location.href = useGeneralStore().fanUrl;
          }
          this.userAuthEmail = "";
          return;
        }
        if (response.data.old_password) {
          this.loginErrorMessage = `You entered an old password, you changed it ${response.data.date_changed}`;
        } else {
          this.loginErrorMessage = "login failed for an unknown reason";
        }
      }).catch(error => {
        this.loginErrorMessage = this.parseError(error);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    parseError(error) {
      if (error.response.data.message) {
        if (error.response.data.message === "The password you entered is incorrect. Please try again.") {
          return "Incorrect password, please try again.";
        }
      }
      return "Login failed, please try again.";
    },
    submitNewFirstName(firstName) {
      if (this.loadingChangeFirstName) {
        return;
      }
      this.loadingChangeFirstName = true;
      $axios.post(`${this.usersApiV1Url}/user/${this.userId}/first_name/change`, {
        first_name: firstName,
      }).then(response => {
        this.loadingChangeFirstName = false;
        const userData = useGeneralStore().userData;
        useGeneralStore().setUserData({
          ...userData,
          first_name: response.data.user_data.first_name,
        });
      }).catch(error => {
        if (error.message) {
          this.userDetailsErrorMessage = error.message;
        } else {
          this.userDetailsErrorMessage = "Submit new first name failed";
        }
        this.loadingChangeFirstName = false;
      });
    },
    submitNewLastName(lastName) {
      if (this.loadingChangeLastName) {
        return;
      }
      this.loadingChangeLastName = true;
      $axios.post(`${this.usersApiV1Url}/user/${this.userId}/last_name/change`, {
        last_name: lastName,
      }).then(response => {
        const userData = useGeneralStore().userData;
        useGeneralStore().setUserData({
          ...userData,
          last_name: response.data.user_data.last_name,
        });
        this.loadingChangeLastName = false;
      }).catch(error => {
        if (error.message) {
          this.userDetailsErrorMessage = error.message;
        } else {
          this.userDetailsErrorMessage = "Submit new last name failed";
        }
        this.loadingChangeLastName = false;
      });
    },
    submitNewPassword(currentPassword, newPassword) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      $axios.post(`${this.usersApiV1Url}/user/${this.userId}/password/change`, {
        current_password: currentPassword,
        new_password: newPassword,
      })
          .then(response => {
            if (!response.data.success) {
              this.userDetailsErrorMessage = response.data.message;
            }
          })
          .catch(error => {
            if (error.message) {
              this.userDetailsErrorMessage = error.response.data.message;
              return;
            }
            this.userDetailsErrorMessage = "Submit new password failed";
          }).finally(() => {
            this.isLoading = false;
          });
    },
    signUpUser(firstName, lastName, email, password) {
      const orgName = `${firstName} ${lastName}`;

      this.isLoading = true;
      $axios.post(`${this.fanApiV1Url}/user/create`, {
        org_name: orgName,
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        app_id: this.appId,
        publication_id: useFanPublicationStore().publicationId,
      }).then(() => {
        if (this.redirect) {
          if (this.redirect.startsWith("http")) {
            window.location.href = `${this.redirect}`;
          } else {
            window.location.href = `https://${window.location.hostname}${this.redirect}`;
          }
        } else {
          window.location.href = useGeneralStore().fanUrl;
        }
        this.userAuthEmail = "";
      }).catch(error => {
        if (error.message) {
          this.signupErrorMessage = error.message;
        } else {
          this.signupErrorMessage = "something unexpected went wrong during signup";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    forgotPassword() {
      this.isLoading = true;
      $axios.post(
        "/user/forgot-password/initiate",
        {
          app_id: this.appId,
          email: this.userAuthEmail,
        },
      ).then(response => {
        if (!response.data.success) {
          this.forgotPasswordErrorMessage = "We are unable to reset your password at this time. Please try again later.";
        }
      }).catch(error => {
        if (error.message) {
          this.forgotPasswordErrorMessage = error.message;
        } else {
          this.forgotPasswordErrorMessage = "We are unable to reset your password at this time. Please try again later.";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    resetPassword(password, userId, token) {
      this.isLoading = true;
      $axios.post(
        `${this.usersApiV1Url}/user/${userId}/forgot-password/complete/${token}`,
        {
          password: password,
        },
      ).then(response => {
        if (response.data.success) {
          this.redirectTo("/login"); // USE RELATIVE REDIRECT
          return;
        }
        this.resetPasswordErrorMessage = "something unexpectedly went wrong resetting password";
      }).catch(error => {
        if (error.message) {
          this.resetPasswordErrorMessage = error.message;
        } else {
          this.resetPasswordErrorMessage = "something unexpectedly went wrong resetting password";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    verifyEmail(userId, emailId, token) {
      this.isLoading = true;
      $axios.post(
        `${this.usersApiV1Url}/user/verify-email/app/${this.appId}`,
        {
          user_id: userId,
          email_id: emailId,
          token: token,
        },
      ).then(response => {
        if (response.data.success) {
          this.redirectTo("/"); // USE RELATIVE REDIRECT
          return;
        }
        this.verifyEmailErrorMessage = "Something went wrong verifying email";
      }).catch(error => {
        if (error.message) {
          this.verifyEmailErrorMessage = error.message;
        } else {
          this.verifyEmailErrorMessage = "something unexpectedly went wrong resetting password";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    logoutUser() {
      this.isLoading = true;
      $axios.post(`${this.fanApiV1Url}/user/logout`).then(response => {
        if (response.data.success) {
          this.redirectTo("/"); // USE RELATIVE REDIRECT
          return;
        }
        this.logoutErrorMessage = "something went wrong during logout";
      }).catch(error => {
        if (error.message) {
          this.logoutErrorMessage = error.message;
        } else {
          this.logoutErrorMessage = "something went wrong during logout";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    async verifyUserExists(email) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const verifyUserUri = `${this.usersApiV1Url}/user/app/${this.appId}/exists?email=${email}`;
      return $axios.get(verifyUserUri).then(response => {
        this.userAuthEmail = email;

        if (response.data.exists === false) {
          if (useConfigurationStore().canSignUp) {
            this.userFlowSteps.push(SIGN_UP_FORM);
          } else {
            this.userFlowSteps.push(PRIVATE_NOTICE);
          }
          return { email, exists: false };
        }

        this.userFirstName = response.data.first_name;
        this.userFlowSteps.push(PASSWORD_FORM);

        return { email, exists: true };
      }).catch(error => {
        if (error.message) {
          this.verifyUserErrorMessage = error.message;
        } else {
          this.verifyUserErrorMessage = "something went wrong checking to see if user has account";
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    fetchEmailStatus() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      $axios.get(`${this.usersApiV1Url}/user/${this.userId}/email/status`)
          .then(response => {
            if (response.data.success) {
              let emailStatus = response.data.email_status;
              if (emailStatus.isEmailConfirmed) {
                emailStatus.requestedNewEmail = this.emailData.email_address;
              }

              this.emailStatus = emailStatus;
            }
            if (!response.data.success) {
              this.userDetailsErrorMessage = response.data.message;
            }
            this.isLoading = false;
          })
          .catch(error => {
            if (error.message) {
              this.userDetailsErrorMessage = error.message;
            } else {
              this.userDetailsErrorMessage = "Check email status failed.";
            }
          });
    },

    cancelEmailChange() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      $axios.post(`${this.usersApiV1Url}/user/${this.userData.user_id}/email/cancel-change`)
          .then(response => {
            if (!response.data.success) {
              this.userDetailsErrorMessage = response.data.message;
              this.isLoading = false;
              return;
            }
            this.emailStatus.isEmailConfirmed = true;
            this.isLoading = false;
          })
          .catch(error => {
            if (error.message) {
              this.userDetailsErrorMessage = error.message;
            } else {
              this.userDetailsErrorMessage = "Cancel email change process failed.";
            }
          });
    },
    redirectTo(href, fallbackHref) {
      return useGeneralStore().redirectTo(href, fallbackHref);
    },
  },
});

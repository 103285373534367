<template>
  <iRow v-if="userLayoutChoices.length > 0" align="right">
    <iSelect v-model="layout" :items="iSelectLayoutChoices" return-value />
  </iRow>
  <SmartFeedPlaceholder v-if="editMode" :smart-feed-layout="defaultSmartFeedLayout" :entity-layout="defaultEntityLayout" />
  <iInfiniteScroll
    v-else
    :has-more="smartFeedStore.hasMore"
    :is-loading="smartFeedStore.isLoading"
    height="fill"
    :scroll-threshold="500"
    @load-more="smartFeedStore.getSmartFeed()"
  >
    <slot name="above-content" />
    <slot />
    <component
      :is="wrapperComponent"
      v-if="smartFeedStore.feed.length > 0"
      :vertical-align="verticalAlign"
      :sticky-headers="stickyHeaders"
      v-bind="wrapperComponentProps"
    >
      <SmartFeedItem
        v-for="feedItem in smartFeedStore.feed"
        :key="`${feedItem.type}-${feedItem.id}`"
        :entity-type="feedItem.type"
        :entity-id="feedItem.id"
        :layout="entityLayout"
        :fields="smartFeedStore.tableFields"
        :show-unknown-entities-as-placeholder="showUnknownEntitiesAsPlaceholder"
        @click="$emit('click:item', feedItem)"
      />
    </component>
    <slot v-else name="empty-state" />
  </iInfiniteScroll>
</template>

<script>
import SmartFeedItem from "SmartFeed/components/SmartFeedItem.vue";
import SmartFeedTable from "SmartFeed/components/SmartFeedTable.vue";
import SmartFeedPlaceholder from "SmartFeed/components/SmartFeedPlaceholder.vue";
import { Enum } from "@bloglovin/vue-component-library";
import BaseSmartFeedEngine from "SmartFeed/smart-feed-engine";
import SmartFeedGraph from "SmartFeed/components/SmartFeedGraph.vue";

export default {
  name: "SmartFeed",
  components: { SmartFeedTable, SmartFeedItem, SmartFeedPlaceholder },
  props: {
    piniaStore: {
      type: Function,
      required: true,
    },
    userLayoutChoices: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultEntityLayout: {
      type: Enum,
      required: true,
      options: [...BaseSmartFeedEngine.VALID_ENTITY_LAYOUTS],
      // @todo add a validator to ensure that default smart feed layout table has default entity layout table and continues to validate.
    },
    defaultSmartFeedLayout: {
      type: Enum,
      options: [...BaseSmartFeedEngine.VALID_SMART_FEED_LAYOUTS],
      required: true,
    },
    // this is currently non-functional after refactor - @TODO - fix
    showUnknownEntitiesAsPlaceholder: {
      type: Boolean,
      required: false,
      default: true,
    },
    stickyHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
    verticalAlign: {
      type: Enum,
      required: false,
      options: ["top", "middle"],
      default: "top",
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["click:item"],
  data() {
    return {
      entityLayout: null,
      smartFeedLayout: null,
      smartFeedStore: null,
    };
  },
  computed: {
    iSelectLayoutChoices() {
      return this.userLayoutChoices.map(userLayoutChoice => {
        return {
          id: userLayoutChoice,
          name: userLayoutChoice,
        };
      });
    },
    wrapperComponent() {
      switch (this.smartFeedLayout) {
        case BaseSmartFeedEngine.SMART_FEED_LAYOUT_TABLE:
          return SmartFeedTable;
        case BaseSmartFeedEngine.SMART_FEED_LAYOUT_GRID:
          return "iMasonry";
        case BaseSmartFeedEngine.SMART_FEED_LAYOUT_LIST:
          return "iColumn";
        case BaseSmartFeedEngine.SMART_FEED_LAYOUT_GRAPH:
          return SmartFeedGraph;
        default:
          return "iRow";
      }
    },
    wrapperComponentProps() {
      switch (this.smartFeedLayout) {
        case BaseSmartFeedEngine.SMART_FEED_LAYOUT_TABLE:
          return { fields: this.smartFeedStore.tableFields };
        case BaseSmartFeedEngine.SMART_FEED_LAYOUT_GRAPH:
          return {
            smartFeedStore: this.smartFeedStore,
            graphType: this.entityLayout,
          };
        case BaseSmartFeedEngine.SMART_FEED_LAYOUT_GRID:
          return { align: "left" };
        default:
          return {};
      }
    },
  },
  created() {
    this.smartFeedStore = this.piniaStore(this.$pinia);
    if (this.smartFeedStore.feed.length === 0) {
      this.smartFeedStore.getSmartFeed();
    }
    this.entityLayout = this.defaultEntityLayout;
    this.smartFeedLayout = this.defaultSmartFeedLayout;
  },
};
</script>

import { useGeneralStore } from "%/stores/general-store";
import { PAGE_MARKETPLACE } from "@/constants/nav-constants";
import { useFeatureStore } from "%/stores/feature-store";

function filterMenuItems(menuItems) {
  const filteredNavItems = [];
  menuItems.forEach(menuItem => {
    if (menuItem.name === PAGE_MARKETPLACE) {
      const isFeatureEnabled = useFeatureStore().isFeatureEnabled("syndicationMarketplace");
      if (!isFeatureEnabled) {
        return;
      }
    }
    if (menuItem.requiredCapabilities && menuItem.requiredCapabilities.length > 0) {
      menuItem.requiredCapabilities.forEach(capability => {
        if (!useGeneralStore().orgCapabilities.includes(capability)) {
          return;
        }
        filteredNavItems.push(menuItem);
      });
    } else {
      filteredNavItems.push(menuItem);
    }
  });
  return filteredNavItems;
}

export {
  filterMenuItems,
};

<template>
  <iModal
    :close-on-backdrop="false"
    :close-on-escape="false"
    :close-on-secondary="false"
    :content-height="80"
    :max-width="600"
    :show-footer="false"
    scrollable
    title="Assign Publishing Platform"
    width="fill"
    @close="close"
  >
    <template #body>
      <iColumn>
        <iTitle>
          Publications
        </iTitle>
        <iRow>
          <template v-if="publications.length === 0">
            <iText variant="subtle">
              You have no active publications.
            </iText>
          </template>
          <template v-else>
            <iStyledCard
              v-for="publication in publications"
              :key="publication.publicationId"
              :max-width="270"
              :splash-image="publication.publicationLogo || DEFAULT_SPLASH_IMAGE"
              :splash-image-max-height="100"
              :title="publication.name"
              width="fill"
            >
              <template #footer>
                <iButton
                  variant="secondary"
                  width="fill"
                  @click="handleSelectPlatform(PUBLISHING_PLATFORM_TYPE.PUBLICATION, publication.publicationId)"
                >
                  Select
                </iButton>
              </template>
            </iStyledCard>
          </template>
        </iRow>
      </iColumn>
      <iColumn>
        <iTitle>
          Social Networks
        </iTitle>
        <iRow>
          <template v-if="socialNetworks.length === 0">
            <iText variant="subtle">
              You have no active social network accounts.
            </iText>
          </template>
          <template v-else>
            <iStyledCard
              v-for="socialNetwork in socialNetworks"
              :key="socialNetwork.social_network_id"
              :max-width="270"
              :splash-image="getSocialNetworkLogo(socialNetwork.social_network_name)"
              splash-image-object-fit="contain"
              :splash-image-max-height="100"
              :title="socialNetwork.social_network_name"
              width="fill"
            >
              <template #footer>
                <iButton
                  variant="secondary"
                  width="fill"
                  @click="handleSelectPlatform(PUBLISHING_PLATFORM_TYPE.SOCIAL_NETWORK, socialNetwork.social_network_id)"
                >
                  Select
                </iButton>
              </template>
            </iStyledCard>
          </template>
        </iRow>
      </iColumn>
      <iColumn>
        <iTitle>
          Email
        </iTitle>
        <iRow>
          <iText variant="subtle">
            Coming Soon
          </iText>
        </iRow>
      </iColumn>
    </template>
  </iModal>
</template>

<script>
import { PUBLISHING_PLATFORM_TYPE } from "%/constants/story-constants";
import { useSocialNetworksStore } from "@/stores/social-networks-store";
import { SOCIAL_NETWORK_LINKS } from "&/constants/socials-constants";
import { STORY_VARIANT_TYPES } from "@/constants/story-variant-type-constants";
import { DEFAULT_SPLASH_IMAGE } from "%/constants/publication-constants";
import { socialNetworkConnectionStatus } from "@/enums/social-network-enums";
import { usePublicationFeedStore } from "SmartFeed/stores/feed/publication-feed-store";
import { usePublicationEntityStore } from "SmartFeed/stores/entity/publication-entity-store";

export default {
  name: "AssignSyndicatedPublishingPlatformModal",
  props: {
    storyId: {
      type: [Number, String],
      required: false,
      default: "",
    },
  },
  emits: ["close", "change"],
  data() {
    return {
      PUBLISHING_PLATFORM_TYPE,
      STORY_VARIANT_TYPES,
      DEFAULT_SPLASH_IMAGE,
    };
  },
  computed: {
    publications() {
      return Object.values(usePublicationEntityStore().entities);
    },
    socialNetworks() {
      return useSocialNetworksStore().socialNetworks.filter(network => [
        socialNetworkConnectionStatus.CONNECTED,
        socialNetworkConnectionStatus.MANUAL_CONNECTION,
      ].includes(network.status));
    },
  },
  mounted() {
    usePublicationFeedStore().getSmartFeed();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getSocialNetworkLogo(name) {
      return SOCIAL_NETWORK_LINKS.find(network => network.name === name).logo;
    },
    handleSelectPlatform(type, id) {
      this.$emit("change", this.getEntity(type, id));
    },
    getEntity(type, id) {
      const publication = this.publications.find(publication => publication.publicationId === id);
      const network = this.socialNetworks.find(socialNetwork => socialNetwork.social_network_id === id);
      switch (type) {
        case PUBLISHING_PLATFORM_TYPE.PUBLICATION:
          return {
            publishing_platform_id: publication.publicationId,
            publishing_platform_type: PUBLISHING_PLATFORM_TYPE.PUBLICATION,
            publishing_platform_name: publication.name,
          };
        case PUBLISHING_PLATFORM_TYPE.SOCIAL_NETWORK:
          return {
            publishing_platform_id: network.social_network_account_id,
            publishing_platform_type: PUBLISHING_PLATFORM_TYPE.SOCIAL_NETWORK,
            publishing_platform_name: network.social_network_name,
          };
        case PUBLISHING_PLATFORM_TYPE.EMAIL:
          return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>

import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useGeneralStore } from "%/stores/general-store";

export const useSyndicatedStoryFeedStore = defineSmartFeedPiniaStore("my-syndicated-story-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    metadata: {
      lastId: Number.MAX_SAFE_INTEGER,
      orgId: useGeneralStore().orgId,
    },
    tableFields: [
      {
        key: "storyTitle",
        label: "Story title",
      },
      {
        key: "publishingPlatformName",
        label: "Publication Name",
      },
      { key: "actions", label: "" },
    ],
  }),
  getters: {
    feedUrl() {
      return `story/${this.metadata.orgId}/stories/pending-author-approval/smart-feed`;
    },
  },
});

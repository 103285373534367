<template>
  <iModal
    v-if="showFeatureConfig"
    :visible="showFeatureConfig"
    :show-footer="false"
    title="Feature Config"
    :close-on-backdrop="false"
    :close-on-escape="false"
    @close="showFeatureConfig = !showFeatureConfig"
  >
    <template #body>
      <!--      <iColumn >-->
      <iRow
        v-for="(feature, featureName) in features"
        :key="featureName"
        width="fill"
        wrap="nowrap"
        align="between"
        :class="['feature-config-row', {'toggled': isFeatureToggled(feature)}]"
      >
        <iColumn width="fill">
          <iText>{{ featureName }}</iText>
        </iColumn>
        <iColumn width="hug">
          <iToggleSwitch v-model="features[featureName].enabled" @change="setFeatureAsManuallyToggled(featureName)" />
        </iColumn>
      </iRow>
      <!--      </iColumn>-->
    </template>
  </iModal>
</template>

<script>
import { mapState } from "pinia";
import { useFeatureStore } from "%/stores/feature-store";

export default {
  name: "FeatureConfig",
  data() {
    return {
      showFeatureConfig: false,
    };
  },
  computed: {
    ...mapState(useFeatureStore, ["features"]),
  },
  created() {
    document.addEventListener("keydown", event => {
      if ((event.ctrlKey || event.metaKey) && event.altKey && (event.key === "a" || event.key === "å")) {
        event.preventDefault();
        this.showFeatureConfig = !this.showFeatureConfig;
      }
    });
  },
  methods: {
    setFeatureAsManuallyToggled(featureName) {
      this.features[featureName].toggled = true;
    },
    isFeatureToggled(feature) {
      return feature.toggled && feature.enabled !== feature.enabled_by_default;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-config-row {
  padding: 10px;
}

.toggled {
  background-color: rgb(149,255,0);
}
</style>

<template>
  <iRow wrap="nowrap">
    <iSpace :width="marginWidth" />
    <iRow :wrap="!$vssMobile ? 'nowrap' : 'wrap'" align="between">
      <iColumn gap="extraSmall">
        <iRow vertical-align="middle">
          <iText variant="subtle">
            Syndication Post (Read-only)
          </iText>
        </iRow>
        <iRow>
          <iText variant="subtle">
            Tags:
          </iText>
          <template v-if="hashtags.length">
            <iButton
              v-for="tag in hashtags"
              :key="tag.hashtag_id"
              variant="text"
            >
              #{{ tag.hashtag }}
            </iButton>
          </template>
          <template v-else>
            <iButton class="text-btn" variant="text">
              No Tags
            </iButton>
          </template>
        </iRow>
      </iColumn>
      <iRow align="right" vertical-align="middle">
        <iButton
          loading-spinner-position="before"
          :loader-on-left="true"
          size="small"
          @click="openAssignSynPublishingPlatformModal"
        >
          {{ publishButtonText }}
        </iButton>
      </iRow>
    </iRow>
    <iSpace :width="marginWidth" />
  </iRow>

  <iEditor
    ref="editor"
    preview
    :segments="segments"
    height="fill"
    overflow="auto"
    variant="builder"
    width="fill"
  >
    <template #above-editor>
      <iRow
        :style-overrides="{marginTop: '8px'}"
        width="fill"
        wrap="nowrap"
      >
        <iHeading font-weight="dark">
          {{ story.title || 'Untitled Syndicated Post' }}
        </iHeading>
      </iRow>
    </template>
  </iEditor>
  <AssignSyndicatedPublishingPlatformModal
    v-if="assignToSyndicatedPublishingPlatformModalOpen"
    :story-id="story.story_id"
    @change="publishToPublication"
    @close="assignToSyndicatedPublishingPlatformModalOpen = false;"
  />
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useWindowStore } from "@bloglovin/vue-component-library";
import { useStoryStore } from "%/stores/story-store";
import AssignSyndicatedPublishingPlatformModal
  from "@/components/publication/AssignSyndicatedPublishingPlatformModal.vue";

export default {
  name: "SyndicatedStoryEditorComponent",
  components: { AssignSyndicatedPublishingPlatformModal },
  data() {
    return {
      assignToSyndicatedPublishingPlatformModalOpen: false,
    };
  },
  computed: {
    ...mapState(useWindowStore, ["$vssMobile"]),
    ...mapState(useStoryStore, [
      "story",
      "isPublishingStory",
    ]),
    publishButtonText() {
      if (this.story.requires_syndication_approval) {
        return "Request Permission";
      }
      return "Publish";
    },
    hashtags() {
      if (!this.story.hashtags) {
        return [];
      }
      return this.story.hashtags;
    },
    segments() {
      if (!this.story.segments || !this.story.segment_order) {
        return [];
      }
      const segmentArray = Object.values(this.story.segments);
      return this.story.segment_order.reduce((acc, curr) => {
        const seg = segmentArray.find(item => item.story_segment_id === curr.story_segment_id);
        if (seg) {
          acc.push(seg);
        }
        return acc;
      }, []);
    },
  },
  methods: {
    ...mapActions(useStoryStore, [
      "publishSyndicationStory",
    ]),
    openAssignSynPublishingPlatformModal() {
      this.assignToSyndicatedPublishingPlatformModalOpen = true;
    },
    publishToPublication(data) {
      this.publishSyndicationStory(this.story.story_id, data.publishing_platform_id);
      this.assignToSyndicatedPublishingPlatformModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(toptap.ProseMirror) {
  height: 100%;
  box-sizing: border-box;
  cursor: default;
}

.native-story-title {
  margin-top: 4px;

  :deep(.i-text.text-edit) {
    width: 100%;
    font-size: 26px;
    font-weight: 700;
  }
}

.text-btn {
  opacity: 0.5;
  cursor: default;
}
</style>

<template>
  <PageWrapper
    :active-page="PAGE_STORIES_SYNDICATION"
    :active-section="SECTION_STORIES"
    title="Syndication"
    page-name="Syndication"
  />
</template>

<script>
import { PAGE_STORIES_SYNDICATION, SECTION_STORIES } from "@/constants/nav-constants";
import PageWrapper from "@/components/PageWrapper.vue";

export default {
  name: "Syndication",
  components: { PageWrapper },
  data() {
    return {
      PAGE_STORIES_SYNDICATION,
      SECTION_STORIES,
    };
  },
};
</script>
